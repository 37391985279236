<template>
    <div>
        <button
            v-if="color === 'green'"
            :disabled="disabled ?? false"
            class="inline-flex w-full justify-center rounded-md border border-green-500 bg-green-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-transparent hover:text-green-500 focus:outline-none sm:w-auto sm:text-sm"
        >
            <slot></slot>
        </button>
        <button
            v-else-if="color === 'red'"
            :disabled="disabled ?? false"
            class="inline-flex w-full justify-center rounded-md border border-red-500 bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-transparent hover:text-red-500 focus:outline-none sm:w-auto sm:text-sm"
        >
            <slot></slot>
        </button>
        <button
            v-else
            :disabled="disabled ?? false"
            class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:w-auto sm:text-sm"
        >
            <slot></slot>
        </button>
    </div>
</template>

<script>
export default {
    name: 'ButtonComponent',
    props: {
        color: {
            type: String,
            required: false
        },
        disabled: {
            type: Boolean,
            required: false
        }
    }
};
</script>

<style scoped></style>
