<template>
    <card-component classes="w-[26rem]">
        <template #body>
            <div class="flex flex-col gap-4 px-8 -mt-6 mb-3">
                <div class="flex flex-col gap-2">
                    <i class="fa-regular text-5xl fa-user-shield"></i>
                    <div>Bitte wählen sie eine Zwei-Faktor-Authentifizierungsmethode aus.</div>
                </div>

                <fieldset class="text-sm flex flex-col gap-4">
                    <div
                        v-for="(mfaMethod, index) in mfaMethods"
                        :key="index"
                        class="flex flex-row gap-2 items-center bg-gray-200 rounded-md p-4 cursor-pointer"
                        @click="selected = mfaMethod.method"
                    >
                        <input
                            v-model="selected"
                            type="radio"
                            class="radio-button"
                            :value="mfaMethod.method"
                            :checked="selected === mfaMethod.method"
                        />

                        <div class="flex flex-col text-secondary text-left">
                            <p class="font-bold">{{ labels[mfaMethod.method]?.label }}</p>
                            <p v-if="mfaMethod.added">Hinzugefügt am {{ formatDate(mfaMethod.added) }}</p>
                        </div>
                    </div>
                </fieldset>

                <button
                    class="btn btn-outline btn-secondary no-animation w-full"
                    :class="{ 'btn-disabled': !selected }"
                    @click="select"
                >
                    Weiter
                </button>
            </div>
        </template>
    </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent';
import { mapStores } from 'pinia';
import { useMfaStore } from '@/stores/mfa';
import moment from 'moment';

export default {
    name: 'MfaSelectMaskComponent',
    components: { CardComponent },
    data() {
        return {
            loading: false,
            selected: undefined,
            mfaMethods: [],
            labels: {
                otp: {
                    label: 'Einmalkennwort (OTP)'
                },
                mail: {
                    label: 'E-Mail'
                },
                backup_code: {
                    label: 'Wiederherstellungscode'
                }
            }
        };
    },
    computed: {
        ...mapStores(useMfaStore)
    },
    created() {
        this.loading = true;
        // fetch mfa methods
        this.mfaStore
            .fetchUserMfaMethods()
            .then((response) => {
                this.loading = false;
                this.mfaMethods = response;

                this.mfaMethods.push({
                    method: 'backup_code'
                });
            })
            .catch(() => {});
    },
    methods: {
        select() {
            this.$router.push({
                path: this.selected === 'backup_code' ? '/login/mfa/reset' : `/login/mfa/${this.selected}`
            });
        },
        formatDate(date) {
            return moment(date).format('DD.MM.YYYY');
        }
    }
};
</script>

<style scoped></style>
