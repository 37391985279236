<template>
    <!-- E-Mail Reset Flow
      Change-Email --| Email with code --| Enter new email --| send verification email --| verify new email
    -->
    <modal-component v-model="value">
        <template #body>
            <div class="flex flex-col gap-4">
                <div class="flex flex-col items-center">
                    <!-- ICON -->
                    <i class="fa-regular text-4xl fa-envelope"></i>
                    <div class="text-secondary text-xl">E-Mail Adresse ändern</div>
                </div>

                <!-- STEPS OVERVIEW -->
                <div class="w-full mx-auto my-2">
                    <div class="bg-gray-200 h-1 flex items-center justify-between">
                        <div
                            v-for="step in steps"
                            :key="step"
                            class="flex justify-between h-1 items-center relative"
                            :class="{ 'bg-primary': step < currentStep, 'w-full': step < steps }"
                        >
                            <div class="h-1 flex items-center">
                                <div
                                    v-if="currentStep === step"
                                    class="bg-white h-6 w-6 rounded-full border border-secondary shadow flex items-center justify-center -mr-3 relative"
                                >
                                    <div class="h-3 w-3 bg-secondary rounded-full"></div>
                                </div>
                                <div
                                    v-else-if="currentStep > step"
                                    class="bg-secondary h-6 w-6 rounded-full shadow-xl flex items-center justify-center"
                                >
                                    <i class="fa-regular fa-check text-white"></i>
                                </div>
                                <div v-else>
                                    <div class="bg-white h-6 w-6 rounded-full border border-secondary shadow"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- CONTENT -->
                <div class="flex flex-col rounded-md">
                    <!-- STEP 1, security code -->
                    <div v-if="currentStep === 1" class="flex flex-col gap-2">
                        <div class="text-secondary font-bold text-left">Verifizierung</div>
                        <div class="font-light">
                            Um eine neue E-Mail Adresse setzten zu können, geben Sie bitte den sechsstelligen Code ein
                            der ihnen soeben per E-Mail gesendet wurde.
                        </div>

                        <div class="flex flex-col mx-auto">
                            <verification-component v-model="codes.identity" :digits="6"></verification-component>
                            <div class="flex flex-col items-end">
                                <div class="flex-shrink text-sm cursor-pointer hover:underline">
                                    E-Mail erneut senden
                                </div>
                            </div>
                        </div>

                        <div class="font-light text-sm">
                            Sollte sich die E-Mail nicht in ihrem Posteingang befinden, überprüfen sie bitte ihren
                            Spamordner.
                        </div>
                    </div>

                    <!-- STEP 2, new email address  -->
                    <div v-if="currentStep === 2" class="flex flex-col gap-2">
                        <div class="text-secondary font-bold text-left">Neue E-Mail Adresse</div>
                        <div class="font-light">Geben Sie nun ihre neue E-Mail Adresse ein.</div>

                        <input
                            v-model="mail"
                            class="appearance-none rounded w-full py-2 px-3 text-secondary mb-3 border border-secondary focus:outline-none focus:border-primary"
                            :placeholder="ssoStore.getUser.mail"
                        />
                    </div>

                    <!-- STEP 3, verify new address verification -->
                    <div v-if="currentStep === 3" class="flex flex-col gap-2">
                        <div class="text-secondary font-bold text-left">Neue E-Mail Adresse verifizieren</div>
                        <div class="font-light">
                            Nun müssen Sie ihre neue E-Mail Adresse nur noch verifizieren. Geben Sie hierzu bitte den
                            sechsstelligen Code ein, der ihnen soeben an ihre neue E-Mail Adresse gesendet wurde.
                        </div>

                        <div class="flex flex-col mx-auto">
                            <verification-component v-model="codes.verification" :digits="6"></verification-component>
                            <div class="flex flex-col items-end">
                                <div class="flex-shrink text-sm cursor-pointer hover:underline">
                                    E-Mail erneut senden
                                </div>
                            </div>
                        </div>

                        <div class="font-light text-sm">
                            Sollte sich die E-Mail nicht in ihrem Posteingang befinden, überprüfen sie bitte ihren
                            Spamordner.
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
            <button-component @click="value = false"> Schließen</button-component>

            <button-component color="green" @click="next()"> Weiter</button-component>
        </template>
    </modal-component>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent';
import VerificationComponent from '@/components/CodeComponent';
import ModalComponent from '@/components/ModalComponent';
import { mapStores } from 'pinia';
import { useSsoStore } from '@/stores/sso';
import Notifications from '@auth/vue-share/utility/notifications';

export default {
    name: 'ChangeEmailAddressComponent',
    components: { ModalComponent, ButtonComponent, VerificationComponent },
    props: {
        modelValue: {
            type: Object,
            default: () => {}
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            value: undefined,

            steps: 3,
            currentStep: 1,

            codes: {
                identity: [],
                verification: []
            },
            mail: undefined
        };
    },
    computed: { ...mapStores(useSsoStore) },
    watch: {
        modelValue(value) {
            this.value = value;

            if (value)
                // send verification e-mail
                this.ssoStore.sendChangeVerificationEmail('identity').catch(() => {
                    this.value = false;
                });
        },
        value(value) {
            this.$emit('update:modelValue', value);
        }
    },
    created() {
        this.value = this.modelValue;
    },
    methods: {
        next() {
            if (this.currentStep === 1) {
                // check if verification code is set
                if (!this.codes.identity || this.codes.identity.length === 0) {
                    Notifications.error(`Bitte geben Sie den Verifizierungscode aus der E-Mail an.`);
                    return;
                }
            } else if (this.currentStep === 2) {
                // check if the new email is set and isn't the same as the current one
                if (!this.mail || !this.mail?.trim()) {
                    Notifications.error(`Bitte geben Sie eine neue E-Mail addresse an.`);
                    return;
                } else if (this.mail === this.ssoStore.getUser.mail) {
                    Notifications.error(`Sie benutzten diese E-Mail Adresse aktuell schon`);
                    return;
                }

                this.ssoStore.sendChangeVerificationEmail('verification', this.mail);
            } else if (this.currentStep === 3) {
                if (!this.codes.verification || this.codes.verification.length === 0) {
                    Notifications.error(`Bitte geben Sie den Verifizierungscode aus der E-Mail an.`);
                    return;
                }

                this.ssoStore
                    .changeEmail(this.codes.identity.join(''), this.codes.verification.join(''), this.mail)
                    .then(() => {
                        this.value = false;
                    });

                this.reset();
                return;
            } else {
                console.log('there are not more steps than 3');
                return;
            }

            this.currentStep += 1;
        },
        reset() {
            this.currentStep = 1;
            this.codes.identity = [];
            this.codes.verification = [];
            this.mail = undefined;
        }
    }
};
</script>

<style scoped></style>
