<template>
    <card-component classes="w-[26rem]">
        <template #body>
            <div class="flex flex-col gap-4 px-8 -mt-6 mb-3">
                <div class="flex flex-col gap-2">
                    <i class="fa-regular text-5xl fa-envelope"></i>
                    <div>
                        Bitte bestätigen Sie ihre identität indem Sie den sechsstelligen Code eingeben der ihnen soeben
                        per E-Mail zugesendet wurde.
                    </div>
                </div>

                <verification-component v-model="code" :digits="6" @enter="authenticate()"></verification-component>

                <button
                    class="btn btn-outline btn-secondary no-animation w-full"
                    :class="{ 'btn-disabled': code.length < 6 }"
                    @click="authenticate"
                >
                    Bestätigen
                </button>

                <div class="flex flex-row justify-around">
                    <router-link class="underline" to="/login/mfa/select">Andere Optionen anzeigen</router-link>
                    <div class="underline cursor-pointer" @clic="send">E-Mail erneut senden</div>
                </div>
            </div>
        </template>
    </card-component>
</template>

<script>
import VerificationComponent from '@/components/CodeComponent';
import CardComponent from '@/components/CardComponent';
import { mapStores } from 'pinia';
import { useMfaStore } from '@/stores/mfa';
import { getOAuthUrl } from '@/main';

export default {
    name: 'MfaMailComponent',
    components: { CardComponent, VerificationComponent },
    data() {
        return {
            code: []
        };
    },
    computed: {
        ...mapStores(useMfaStore)
    },
    created() {
        // TODO: decide if its better that the backend sends the email
        this.send();
    },
    methods: {
        send() {
            this.mfaStore.sendCode('mail').catch(() => {});
        },
        authenticate() {
            this.mfaStore
                .verifyCode('mail', this.code.join(''))
                .then(() => {
                    const searchParams = new URLSearchParams(location.search);
                    searchParams.delete('prompt');

                    let path = `/oauth2/authorize?${searchParams.toString()}`;

                    if (!searchParams.get('redirect_uri')) path = '/login';

                    window.location.href = `${getOAuthUrl()}${path}`;
                })
                .catch(() => {
                    this.code = [];
                });
        }
    }
};
</script>

<style scoped></style>
