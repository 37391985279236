<template>
    <card-component classes="w-[26rem]">
        <template #body>
            <div class="flex flex-col gap-4 px-8 -mt-6 mb-3">
                <div class="flex flex-col gap-2">
                    <i class="fa-regular text-5xl fa-mobile"></i>
                    <div>
                        Bitte bestätigen Sie ihre identität indem Sie einen OTP Code aus ihrere Authenticator App (z.B.
                        Google Authenticator oder Authy) eingeben.
                    </div>
                </div>

                <verification-component v-model="code" :digits="6" @enter="authenticate()"></verification-component>

                <button
                    class="btn btn-outline btn-secondary no-animation w-full"
                    :class="{ 'btn-disabled': code.length < 6 }"
                    @click="authenticate"
                >
                    Bestätigen
                </button>

                <router-link class="underline" to="/login/mfa/select">Andere Optionen anzeigen</router-link>
            </div>
        </template>
    </card-component>
</template>

<script>
import VerificationComponent from '@/components/CodeComponent';
import CardComponent from '@/components/CardComponent';
import { mapStores } from 'pinia';
import { useMfaStore } from '@/stores/mfa';
import { getOAuthUrl } from '@/main';

export default {
    name: 'MfaOtpComponent',
    components: { CardComponent, VerificationComponent },
    data() {
        return {
            code: []
        };
    },
    computed: {
        ...mapStores(useMfaStore)
    },
    methods: {
        authenticate() {
            this.mfaStore
                .verifyCode('otp', this.code.join(''))
                .then(() => {
                    const searchParams = new URLSearchParams(location.search);
                    searchParams.delete('prompt');

                    let path = `/oauth2/authorize?${searchParams.toString()}`;

                    if (!searchParams.get('redirect_uri')) path = '/login';

                    window.location.href = `${getOAuthUrl()}${path}`;
                })
                .catch(() => {
                    this.code = [];
                });
        }
    }
};
</script>

<style scoped></style>
